<!--教师编辑-->
<template>
    <a-drawer :title="title" :width="650" :visible="drawerVisible" :body-style="{ paddingBottom: '80px' }" @close="handleDrawer(false)">
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
            <a-form-item label="教师姓名：" name="realname">
                <a-input v-model:value="form.realname" placeholder="请输入教师姓名" />
            </a-form-item>
            <a-form-item label="教师电话：" name="mobile">
                <a-input v-model:value="form.mobile" placeholder="请输入教师电话" />
            </a-form-item>
            <a-form-item label="学校名称：" name="school">
                <a-input v-model:value="form.school" placeholder="请输入学校名称" />
            </a-form-item>
            <a-form-item label="年级：" name="grade_id">
                <a-select ref="select" v-model:value="form.grade_id" placeholder="请选择年级">
                    <a-select-option v-for="(item,idx) in seletArray.grade" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="科目：" name="subject_id">
                <a-select ref="select" v-model:value="form.subject_id" placeholder="请选择科目">
                    <a-select-option v-for="(item,idx) in seletArray.subject" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="教师账号：" name="username">
                <a-input v-model:value="form.username" placeholder="请输入教师账号" autocomplete="off"/>
            </a-form-item>
            <a-form-item label="密码" :name="form.id ? '' : 'pwd'">
                <a-input-password v-model:value="form.pwd" placeholder="请输入密码" autocomplete="off"/>
            </a-form-item>
        </a-form>
        <div class="submitBtn">
            <a-space>
                <a-button block @click="handleDrawer(false)">取消</a-button>
                <a-button block type="primary" @click="handleSubmit">保存</a-button>
            </a-space>
        </div>
    </a-drawer>
</template>
<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, toRefs, getCurrentInstance, onMounted, } from 'vue';
import md5 from 'js-md5';
export default defineComponent({
    name: 'TeacherEdit',
    components: {
        LoadingOutlined,
        PlusOutlined,
    },
    props: {},

    setup(props, ctx) {
        const { proxy } = getCurrentInstance();
        // 抽屉开关
        const drawerVisible = ref(false);
        const formRef = ref();
        onMounted(() => {
            SelectList();//下拉列表
        });
        const initData = reactive({
            form: {},
            seletArray: {},
            rules: {
                realname: [{
                    required: true,
                    message: '请输入教师姓名',
                }],
                mobile: [{
                    required: true,
                    message: '请输入教师电话',
                    pattern: /^1[3-9]\d{9}$/,
                }],
                school: [{
                    required: true,
                    message: '请输入学校名称',
                }],
                grade_id: [{
                    required: true,
                    message: '请选择年级',
                }],
                subject_id: [{
                    required: true,
                    message: '请选择科目',
                }],
                username: [{
                    required: true,
                    message: '请输入账号',
                }],
                pwd: [{
                    required: true,
                    message: '请输入密码',
                }]
            },
            title: '添加教师信息',
        });
        const SelectList = () => {
            proxy.http.post('/admin.chapter/config').then(response => {
                initData.seletArray = response;
            })
        }

        // 保存
        const handleSubmit = () => {
            formRef.value.validate().then(() => {
                initData.form.password = initData.form.pwd ? md5(initData.form.pwd) : '';
                delete initData.form.pwd;
                proxy.http.post('/admin.teacher/save', initData.form).then((re) => {
                    proxy.message.success('提交成功');
                    ctx.emit('success')
                    handleDrawer(false)
                })
            }).catch(error => {
                console.log('error', error);
            });
        }

        //打开抽屉弹框
        const handleDrawer = (states) => {
            drawerVisible.value = states;
            if (!drawerVisible.value) formRef.value.resetFields();
        };
    

        return {
            ...toRefs(initData),
            SelectList,
            drawerVisible,
            handleDrawer,
            handleSubmit,
            formRef
        };
    },
});
</script>

