<!--教师管理-->
<template>
    <div>
        <a-row class="searchBox">
            <a-space class="flex flex-wrap">
                <a-input-search v-model:value="param.search" placeholder="输入教师姓名/电话" style="width: 320px;" enter-button="搜索" @search="filterList" />
                <a-button type="primary" class="marginLeft" @click="handleEdit()">
                    <PlusCircleOutlined />添加教师
                </a-button>
            </a-space>
        </a-row>

        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #video_count="{text, record, index}">
                    <a-switch checked-children="开" un-checked-children="关" v-model:checked="checked1" />
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <!--添加教师-->
        <TeacherEdit ref="TeacherEdit" @success="getList"></TeacherEdit>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import TeacherEdit from '@/components/TeacherEdit';
import useDelete from '@/hooks/useDelete.js';
import { PlusCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
    components: {
        PlusCircleOutlined,
        TablePagination,
        TeacherEdit,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const TeacherEdit = ref();
        onMounted(() => {
            getList();//教师列表
        });
        const initData = reactive({
            seletArray: {},//下拉列表
            list: [],//教师列表
            columns: [
                { title: 'ID', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '姓名', dataIndex: 'realname' },
                { title: '电话', dataIndex: 'mobile' },
                { title: '学校', dataIndex: 'school' },
                { title: '年级', dataIndex: 'grade.name' },
                { title: '科目', dataIndex: 'subject.name' },
                { title: '账号', dataIndex: 'username' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],

            param: {
                curr: 1,
                row: 10
            },
            count: 0,
            pages: 1
        });

        const getList = () => {
            proxy.http.post('/admin.teacher/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        const filterList = () => {
            Object.assign(initData.param, { curr: 1 });
            Object.assign(initData, { list: [] });
            getList();
        }

        //分页change
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };


        //新建弹框
        const handleEdit = (row) => {
            if (row) {
                let { realname, username, mobile, school, grade_id, subject_id } = row;
                TeacherEdit.value.form = {
                    id: row.id,
                    username,
                    realname,
                    mobile,
                    school,
                    grade_id,
                    subject_id,
                };
            } else {
                TeacherEdit.value.form = {};
            }
            TeacherEdit.value.title = row && row.id ? '编辑教师信息' : '添加教师信息';
            TeacherEdit.value.handleDrawer(true);
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.teacher/delete', [row.id]).then(() => {
                getList();
            })
        }

        return {
            ...toRefs(initData),
            getList,
            TeacherEdit,
            changeTableRequest,
            handleEdit,
            handleDelete,
            filterList
        }
    }
})
</script>
